import React, { useEffect, useRef, useState } from 'react';
import './mc-precheck-page.styles.scss';
import TitleCard from '../title-card/title-card.component';
import { useNavigate } from 'react-router-dom';
import Modal, { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import UploadForm from '../upload-form/upload-form.component';
import EvidenceCard from '../evidence-card/evidence-card.component';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import FullPageLoader from '@/components/SHARED/loader/full-page-loader.component';
import { checkForChanges } from '@/utils/checkForChanges';

const McPrecheckPage = ({ mcData, mcProgress }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({'upload': ['','',''], 'exp': ['','','']});
    const [ evalData, setEvalData ] = useState(null);
    const [ showDetailModal, setShowDetailModal ] = useState(false);
    const [ detailList, setDetailList ] = useState(null);
    const [ showUploadModal, setShowUploadModal ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ evNumber, setEvNumber ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);
    const [ viewEvalComments, setViewEvalComments ] = useState(false);
    const navigate = useNavigate();
    const dataRef = useRef({'upload': ['','',''], 'exp': ['','','']});
    const approveRef = useRef();
    const oldRef = useRef();

    useEffect(() => {
        if (!mcData || !mcProgress) return;
        console.log('stuff is changing');
        oldRef.current = {...mcProgress};
        if (mcProgress.eval_precheck) {
            setEvalData({...mcProgress.eval_precheck});
            if (mcProgress.eval_precheck.prev_comments) {
                setViewEvalComments(true);
            }
        }
        const savedData = JSON.parse(window.localStorage.getItem(`precheck_${mcData.mc_key}`))
        if (savedData) {
            dataRef.current = {...savedData};
            setFormData(dataRef.current);
        } else if (mcProgress.content_precheck) {
            dataRef.current = {...mcProgress.content_precheck};
            setFormData(dataRef.current);
        }
        if (mcProgress.content_precheck && mcProgress.content_precheck.status !== 'declined') {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [mcData, mcProgress])

    useEffect(() => {
        if (!formData) {return;}
        // dataRef.current = formData
        console.log(formData)
        // console.log(mcProgress);
    }, [formData])

    useEffect(() => {
        if (!mcData) {return;}
        let detailArr = []
        for (let eo of Object.values(mcData.content)) {
            for (let d of Object.values(eo.comp)) {
                detailArr.push(d)
            }
        }
        setDetailList(detailArr.sort((a,b) => a.id > b.id ? 1 : -1))
    }, [mcData])
    
    function saveData(key, value) {
        if (key === 'detailId') {
            setShowDetailModal(false);
        }
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        window.localStorage.setItem(`precheck_${mcData.mc_key}`, JSON.stringify(dataRef.current));
        console.log(key, value);
        console.log(oldRef.current['content_precheck'] ? oldRef.current['content_precheck'].exp : "");
        console.log(dataRef.current);
    }

    function chooseEvidenceFromModal(number) {
        setEvNumber(number);
        setShowUploadModal(true)
    }

    function saveArtifactChoice(id) {
        let uploads = dataRef.current.upload;
        
        uploads[evNumber] = id;
        saveData('upload', uploads);
        setEvNumber(null);
    }

    function saveExplanation(number, value) {
        let exps = [...dataRef.current.exp];
        exps[number] = value;
        saveData('exp', exps);
    }

    function removeEvidence(number) {
        let uploads = dataRef.current.upload;
        uploads[number] = '';
        saveData('upload', uploads);
    }

    function verifySubmit() {
        let tempData = {...dataRef.current};
        let details = []
        Object.values(mcProgress).map(p => p.status === 'declined' && details.push(p.id));
        const stillMatch = checkForChanges(oldRef.current, {'content_precheck': tempData}, details);
        console.log(stillMatch);
        if (stillMatch.length > 0) {
            setConfirmMessage('WARNING: Your Precheck Submission has not been changed since the last time it was submitted. Are you sure you want to submit the same content again?')
            approveRef.current = () => {
                submitPrecheck();
            }
            return;
        }

        setConfirmMessage('Are you sure you are ready to submit this Precheck to be reviewed? This action cannot be undone.')
        approveRef.current = () => {
            submitPrecheck();
        }
        
    }

    async function submitPrecheck() {
        setLoading(true);
        console.log('approved')
        let tempData = {...dataRef.current};
        tempData.id = 'precheck';
        tempData.timestamp = new Date().getTime();
        tempData.status = 'pending';
        const evalEmailData = {
            subject: `Precheck Artifact and Self-Analysis Submitted [${mcData.name}]`,
            title: 'Precheck Artifact and Self-Analysis Submitted',
            // get eval emails on the back end
            body: '<p>A precheck artifact was submitted on '+ format(new Date(), 'P') +' by <b>'+userProfile.name+'</b> in the Micro-Credential <b>'+mcData.name+'</b>. Please click the button below to go to the ELN Micro-Credential Portal to review this submission.</p>',
        }
        const partEmailData = {
            email: userProfile.email,
            subject: `Precheck Artifact and Self-Analysis Submitted [${mcData.name}]`,
            title: 'Your Precheck Artifact and Self-Analysis Has Been Submitted',
            // get eval emails on the back end
            body: `<p>${userProfile.name},</p><p>Your precheck artifact and self-analysis was submitted on ${format(new Date(), 'P')} in the Micro-Credential <b>${mcData.name}</b>. Please allow <b>5 business days</b> for your pre-check to be assessed. Once the evaluator has recorded a decision and provided notes on your precheck, you will receive another email like this one. You will then be able to either edit your precheck if it is not approved or move on to the rest of the Micro-Credential if it is approved.</p><p>If you do not receive notification that the pre-check has been assessed within 5 business days, please contact <a href="mailto:support@edleadersnetwork.org" target="_blank">support@edleadersnetwork.org</a>.</p>`,
        }
        const res = await UseCloudFunction('saveMcUserContent', {
            'profile': userProfile, 
            'mc_key': mcData.mc_key,
            'path': 'content_precheck',
            'action': 'precheck-submitted',
            'content': tempData,
            'evalEmailData': evalEmailData,
            'partEmailData': partEmailData,
        })
        console.log(res);
        if (res.error) {
            setAlertMessage(`<p>Something went wrong. Please try again later.</p>Error: ${res.error}`);
            setLoading(false);
            return;
        }

        clearForm();
    }

    function clearForm() {
        window.localStorage.removeItem(`precheck_${mcData.mc_key}`);
        dataRef.current = {'upload': [], 'exp': []}
        setFormData(dataRef.current);
        setLoading(false);
        navigate(-1);
    }

    return (
        <div className='precheck-page'>
            <TitleCard mcData={mcData} title='Precheck Artifact Upload and Self-Analysis' />
            <div className='g-card'>
               <div className='card-head'>Precheck Instructions</div>
                <hr />
                <p><b>Purpose:</b> The purpose of the precheck is to provide the participant practice demonstrating his/her ability to upload and analyze an artifact that demonstrates detail competency for one of the micro-credential outcomes. This allows the participant to receive feedback from the assessor to ensure understanding and increase the probability of success prior to submission of the participant's complete portfolio.</p>
                <p>The participant can choose any detail from any of the micro-credential's essential outcomes for this “trial run.” The participant will receive feedback on both the quality of the evidence submitted as well as an analysis of whether he/she met the expectations of artifact self-analysis. Further, the assessor will determine if the artifact and self-analysis successfully demonstrated proficiency of the outcome's detail.</p>
                <p>This important step sets the participant up for greater success when submitting the full artifact portfolio.</p>
                <p>If the precheck submission does not initially demonstrate the expected level of competency, the participant will be allowed to revise and re-upload a modified artifact and/or self-analysis explanation.</p>
                <p>Once the assessor approves the precheck artifact and self-analysis, the outcome's detail will count toward the full portfolio upload and submission, ensuring the participant does not have to re-upload and re-analyze the same detail.</p>
                <p>If further guidance is needed, refer to the training materials.</p>
                <hr />
                <ol>
                    <li>Choose an essential outcome detail for which you will provide evidence.</li>
                    <li>Upload an online artifact.<br />
                    <b>NOTE:</b> Participants will be allowed to upload a maximum of three artifacts to demonstrate proficiency.</li>
                    <li>Give the uploaded artifact a short name for future reference.<br />
                    <b>NOTE:</b> Uploaded files have a 30mb size limit.</li>
                    <li>Indicate the exact location of the evidence within the artifact. (video time stamp, page number, paragraph number, line number, etc.) Complete a self-analysis narrative explaining how this artifact demonstrates your personal proficiency of the selected detail.</li>
                    <li>Upon completion, click the "Submit" button below.<br />
                    <b>NOTE:</b> Once you have submitted your pre-check evidence and analysis, this section will show as “disabled Approval,” and it will not be accessible until it has been reviewed.</li>
                </ol>
            </div>
            {
                (evalData) &&
                <div className='g-card interactive-card'>
                    <div className='card-head'>Evaluator Comments</div>
                    <hr />
                    <div className='comments'>
                    {
                        (evalData.prev_comments) &&
                        Object.values(evalData.prev_comments)
                        .sort((a,b) => a.timestamp > b.timestamp ? 1 : -1)
                        .map(c => (
                            <div key={c.timestamp} className='previous-comment'>
                                <b>{format(new Date(c.timestamp), 'Pp')}: </b>
                                {c.comment}
                                <div className="g-space-0-5"></div>
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            <div className='g-card interactive-card'>
                <div className='card-head'>Step 1: Choose an Essential Outcome Detail to which you want to apply this evidence</div>
                <hr />
                {
                    (detailList && formData.detailId)
                    ?
                    <div className='chosen-detail'>
                        <button className={`g-button ${disabled && 'disabled'}`} onClick={() => setShowDetailModal(true)}>Choose a Different Detail</button>
                        <p><b>{formData.detailId}: </b>{detailList.filter(d => d.id === formData.detailId)[0].desc}</p>
                    </div>
                    :
                    <div className='chosen-detail'>
                        <button className='g-button' onClick={() => setShowDetailModal(true)}>Choose a Detail</button>
                        <p>No detail chosen yet...</p>
                    </div>
                }
            </div>
            <div className='g-card interactive-card'>
                <div className='card-head'>Step 2: Submit Artifacts, Location and Explanation</div>
                <hr />
                <div className='g-list-item'>
                    <EvidenceCard 
                        mcProgress={oldRef.current} 
                        title='Evidence 1 (required)' 
                        id='ev1' 
                        hidden={false} 
                        disabled={disabled}
                        formData={formData} 
                        evNumber={0} 
                        chooseEvidenceFromModal={chooseEvidenceFromModal}
                        saveExplanation={saveExplanation} 
                        removeEvidence={removeEvidence}
                        sectionId='precheck'
                    />
                    
                </div>
                <div className="g-space-2"></div>
                <div className='g-list-item'>
                    <EvidenceCard 
                        mcProgress={oldRef.current} 
                        title='Evidence 2 (optional)' 
                        id='ev2' 
                        hidden={!formData.exp[1]} 
                        disabled={disabled}
                        formData={formData} 
                        evNumber={1} 
                        chooseEvidenceFromModal={chooseEvidenceFromModal}
                        saveExplanation={saveExplanation} 
                        removeEvidence={removeEvidence}
                        sectionId='precheck'
                    />
                </div>
                <div className="g-space-2"></div>
                <div className='g-list-item'>
                    <EvidenceCard 
                        mcProgress={oldRef.current} 
                        title='Evidence 3 (optional)' 
                        id='ev3' 
                        hidden={!formData.exp[2]} 
                        disabled={disabled}
                        formData={formData} 
                        evNumber={2} 
                        chooseEvidenceFromModal={chooseEvidenceFromModal}
                        saveExplanation={saveExplanation} 
                        removeEvidence={removeEvidence}
                        sectionId='precheck'
                    />   
                </div>
                <div className="g-space-1"></div>
            </div>
            <div className='g-card interactive-card'>
                <div className='card-head'>Step 3: Submit Precheck Artifacts and Explanation for Review</div>
                <hr />
                <div className='buttons'>
                    {
                        (disabled)
                        ?
                        <button className='g-button' onClick={() => navigate(-1)}>Done</button>
                        :
                        (loading) 
                        ?
                        <button className='g-button'><ThreeDotsLoader /></button>
                        :
                        <>
                            <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                            {
                                (formData.upload[0] && formData.exp[0] && formData.detailId)
                                ?
                                <button className='g-button primary' onClick={() => verifySubmit()} >Submit Precheck For Review</button>
                                :
                                <button className='g-button disabled'>Submit Precheck For Review</button>
                            }
                        </>
                    }
                </div>
            </div>
            <Modal show={showDetailModal} cancel={() => setShowDetailModal(false)} closeButton={true} text='Choose an Essential Outcome Detail'>
                <div className='detail-list'>
                    {
                    (detailList) &&
                    detailList.map(d => (
                        <div key={d.id} className='g-list-item clickable' onClick={() => saveData('detailId', d.id)}>
                            <b>{d.name}:</b> {d.desc}
                        </div>
                    ))
                    }
                </div>
            </Modal>
            <Modal show={showUploadModal} cancel={() => setShowUploadModal(false)} closeButton={true} cls='wide' text='Choose an Existing Upload or Add a New One'>
                <UploadForm mcData={mcData} mcProgress={mcProgress} cancel={() => setShowUploadModal(false)} setAlertMessage={setAlertMessage} chooseArtifact={saveArtifactChoice} />
            </Modal>
            <Modal show={viewEvalComments} cancel={() => setViewEvalComments(false)} closeButton={true} text='Evaluator Comments:'>
                {
                    (evalData && evalData.prev_comments) &&
                    Object.values(evalData.prev_comments)
                    .sort((a,b) => a.timestamp > b.timestamp ? 1 : -1)
                    .map(c => (
                        <div key={c.timestamp} className='previous-comment'>
                            <b>{format(new Date(c.timestamp), 'Pp')}: </b>
                            {c.comment}
                            <div className="g-space-0-5"></div>
                        </div>
                    ))
                }
            </Modal>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} /> 
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default McPrecheckPage