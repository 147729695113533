import React, { useEffect, useRef, useState } from 'react'
import './sso.styles.scss'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { signInWithCustomToken, updateProfile, updateEmail, verifyBeforeUpdateEmail, sendSignInLinkToEmail } from "firebase/auth";
import { auth, signInWithGoogle, UseCloudFunction } from '@/utils/firebase.utils'

import { useSelector, useDispatch } from 'react-redux'
import { setDbData, setUserProfile } from '@/state/slices/userSlice';

// import GetProfileData from '@/utils/getProfile'

import Loader from '@/components/SHARED/loader/loader.comp';
import { ModalAlert } from '@/components/SHARED/modal/modal.component';


const SSO = () => {

 	// const { token } = useParams()
	const [searchParams, setSearchParams] = useSearchParams();
    const [ alertMessage, setAlertMessage ] = useState(null);
	const token = searchParams.get("token")
	const intURL = searchParams.get("intendedurl")
    const approveRef = useRef();
  	const navigate = useNavigate()
	const dispatch = useDispatch()

 	useEffect(() => {
		async function fetchData() {
			console.log(token, intURL)
			const authData = await UseCloudFunction('tokenAuth', {'token': token})
			console.log(authData)
			if (
				authData.error && 
				authData.error === 'account not verified'
			) {
				navigate('/logout');
				return;
			}	
            // else if (authData.error) {
            //     setAlertMessage('We\'re sorry, but you have not been granted access to the this Platform. Please contact <a href="mailto:arlin@ilprincipals.org" target="_blank">Arlin Peebles</a> if you believe this is in error.');
            //     return;
            // }
			signInWithCustomToken(auth, authData.token)
			.then(async (userCredential) => {
				// Signed in
				updateProfile(auth.currentUser, {
					displayName: authData.displayName,
				})
				console.log(authData.email)
				console.log(auth.currentUser);
                if (!auth.currentUser.email) {
                    const actionCodeSettings = {
                        // URL you want to redirect back to. The domain (www.example.com) for this
                        // URL must be in the authorized domains list in the Firebase Console.
                        url: 'http://mcv2.edleadersnetwork.org/signinwithlink',
                        // This must be true.
                        handleCodeInApp: true,
                        // dynamicLinkDomain: 'vite-dev.tamingthetech.com',
                    };
                    console.log(actionCodeSettings)
                    try {
                        window.localStorage.setItem('emailForSignIn', authData.email);
						if (!authData.email) {
							UseCloudFunction(
								'saveLogFromClient', {
									'email': authData.email,
									'type': 'error',
									'mess': 'no email when signing in',
									'functName': 'signInWithCustomToken - sso.page.jsx',
									'specs': {} 
								}
							)
						}
                        await sendSignInLinkToEmail(auth, authData.email, actionCodeSettings)
						.then(() => {
							// The link was successfully sent. Inform the user.
							// Save the email locally so you don't need to ask the user for it again
							// if they open the link on the same device.
							window.localStorage.setItem('emailForSignIn', authData.email);
							console.log('email sent');
							// ...
						  })
						  .catch((error) => {
							const errorCode = error.code;
							const errorMessage = error.message;
							console.log(`${errorCode} - ${errorMessage}`);
							// ...
						  });
                        console.log('Email verification sent.');
                        setAlertMessage(`An email verification has been sent to ${authData.email}. You must verify your email address before signing into the platform. If you do not receive the email within the next 5-10 minutes, please check your spam folder or junk mail.`);
                        // window.open('https://edleadersnetwork.org/micro-credential-overview/', '_self')
                        approveRef.current = () => {
                            window.open('https://edleadersnetwork.org', '_self');
                            return;
                        }
                        return;
                    } catch (err) {
                        console.log(err);
                        return;
                    }
                }
				// updateEmail(auth.currentUser, authData.email)
				let twentyMinutesLater = new Date();
				twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 20);
				dispatch(setDbData(authData.fullData))
				// console.log('calling for profile data')
				// const profileData = await UseCloudFunction('getProfileData', {'cst_key': authData.fullData.Individual.ind_cst_key});
				// console.log(profileData)
                dispatch(setUserProfile(authData.fullData.profile))
                if (authData.fullData.profile.ts) {
                    navigate(intURL)    
                } else {
                    navigate(`/profile`);
                }
				
			})
			.catch((error) => {
				var errorCode = error.code;
				var errorMessage = error.message;
				// ...
			});
		}
		if (token && intURL) {
            console.log('fetchingData')
			fetchData();
		}
		
    // navigate('/home')
	}, [token, intURL])

	  
	return (
		<div className='sso-page'>
			{/* <TopBar /> */}
  			<div>
				<Loader />
			</div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} onApprove={approveRef.current} />
		</div>
	)
}

export default SSO