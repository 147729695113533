import React from 'react';
import './admin-functions.styles.scss';
import { UseCloudFunction } from '@/utils/firebase.utils';

const AdminFunctions = () => {

    async function runFunction(fn) {
        let obj = {}
        if (fn === 'sendEmailWithSendGrid') {
            obj = {
                'email': 'adam.geisen@ilprincipals.org',
                'bcc': [
                    {email:'adam@tamingthetech.org'},
                    {email: 'geisenaj@gmail.com'}
                ],
                'subject': 'This is a test email',
                'title': 'Here\'s a test email for ELN Micro-Credentials',
                'body': `<p>Dear Testy,</p>
                    <p>This is a test email with html content. Here is a list: </p>
                    <ol>
                    <li>
                    Here's an item.
                    </li>
                    <li>
                    Here's another item.
                    </li>
                    </ol>
                    <p>And the final paragraph</p>`,
                'buttonLink': 'https://mcv2.edleadersnetwork.org',
                'buttonText': 'Go to the Micro-Credential Portal'
            }
        }
        const res = await UseCloudFunction(fn, {'emailData': obj});
        console.log(res);
    }

    return (
        <div className='admin-functions'>
            <button 
                className='g-button'
                onClick={() => runFunction('sendTestSendGridEmail')} >
                Send Test Email
            </button>
        </div>
    )
}

export default AdminFunctions