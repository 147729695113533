import React, { useEffect } from 'react'
import { auth, UseCloudFunction } from '@/utils/firebase.utils'
import { EmailAuthProvider, getAuth, isSignInWithEmailLink, linkWithCredential, onAuthStateChanged, signInWithEmailLink } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'


const SignInWithLink = () => {

    const navigate = useNavigate();

    useEffect(() => {
        console.log(auth.currentUser);
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log(user);
            
                if (isSignInWithEmailLink(auth, window.location.href)) {

                    let email = window.localStorage.getItem('emailForSignIn');
                    console.log(email);
                    if (!email) {
                        // User opened the link on a different device. To prevent session fixation
                        // attacks, ask the user to provide the associated email again. For example:
                        email = window.prompt('Please provide your email for confirmation.');
                    }
                    if (!email) {
                        UseCloudFunction(
                            'saveLogFromCliend', {
                                'email': email,
                                'type': 'error',
                                'mess': 'no email while signing in',
                                'functName': 'onAuthStateChanged - sign-in-with-link.jsx',
                                'specs': {} 
                            }
                        )
                    }
                    const credential = EmailAuthProvider.credentialWithLink(email, window.location.href);
                    
                    // Link the credential to the current user.
                    linkWithCredential(user, credential)
                    .then((usercred) => {
                        console.log(usercred.user);
                        window.localStorage.removeItem('emailForSignIn');
                        navigate('/')
                    })
                    .catch((error) => {
                        alert(error);
                    });
                    
                }
            } else {
                // if (currentUser) {
                //     navigate('/logout')
                // }
                navigate('/')
                
            }
        });

    }, [auth.currentUser])

    return ( 
        <div></div>
    )
}

export default SignInWithLink